import React from 'react'
import { Helmet } from 'react-helmet'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Swiper from 'react-id-swiper'

/* COMPONENTS */
import Menu from '../components/receitas/receitas-header'
import Layout from '../components/Layout/index'
import SideBar from '../components/receitas/SideBarReceitas/sideBarReceitas'
import SectionLancamentos from '../components/SectionLancamentos/sectionLancamentos'
import InputSearch from '../components/receitas/SideBarReceitas/inputSearch'
import BannerDiaExpress from '../components/Diaexpress/banner-dia-express'

/* STYLES */
import './styles/receitas.scss'

const MaiorPost = (props) => {
  const data = props.data
  return (
    <div className='col-12 p-0 pb-4'>
      <Link to={`/receitas/${data.categoriaSlug}/${data.slug}/`} >
        <div className='d-flex flex-column align-items-start justify-content-end w-100 position-relative top-border-card receita-custom-height-postmaior'>
          <GatsbyImage
            image={data.image.localFile.childImageSharp.gatsbyImageData}
            className='h-100 w-100'
            alt='Card Receita Destaque' />
          <span className='position-absolute font-size-17 text-white bg-dia-blue rounded mx-4 px-2 py-0 mb-3 rounded'>
            {data.categoria}
          </span>
        </div>
        <div className=' col-12 pt-3 pb-4 px-4 bottom-border-card'>
          <h3 className='text-uppercase mt-2 m-0 p-0'>
            <span className='text-red'>{data.title}</span>
          </h3>
          {data.descricao &&
            <p className='text-dark'>
              {data.descricao.descricao}
            </p>
          }
        </div>
      </Link>
    </div>
  )
}

const OtherPosts = (props) => {
  const data = props.data.edges
  return <>
    <div className='row d-none d-sm-flex'>
      {
        data.map((receita, index) => {
          return (
            <Link to={`/receitas/${receita.node.categoriaSlug}/${receita.node.slug}/`} className="text-dark col-12 col-sm-6 pb-4" key={`otherPost${index}`}>
              <div className='d-flex flex-column align-items-start'>
                <div className='d-flex flex-column align-items-start justify-content-end w-100 top-border-card'>
                  <GatsbyImage
                    image={receita.node.image.localFile.childImageSharp.gatsbyImageData}
                    className='w-100 receita-custom-height-card'
                    alt='Card Receita' />
                  <span className='position-absolute font-size-17 text-white bg-dia-blue rounded mx-4 px-2 py-0 mb-3'>
                    {receita.node.categoria}
                  </span>
                </div>
                <div className='bottom-border-card w-100 py-2 px-4'>
                  <span className='font-size-15 py-2'>{receita.node.title}</span>
                </div>
              </div>
            </Link>
          )
        })
      }
    </div>
  </>
}

const OtherPostsMobile = (props) => {
  const data = props.data.edges

  const params = {
    slidesPerView: 1.4,
    spaceBetween: 24,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  }

  return (
    <div className='receitaPostsMobile d-block d-sm-none'>
      <Swiper {...params}>
        {
          data.map((receita, index) => {
            return (
              <Link to={`/receitas/${receita.node.categoriaSlug}/${receita.node.slug}/`} className="text-dark" key={`otherPost${index}`}>
                <div className='d-flex flex-column align-items-start'>
                  <div className='d-flex flex-column align-items-start justify-content-end w-100 top-border-card'>
                    <GatsbyImage
                      image={receita.node.image.localFile.childImageSharp.gatsbyImageData}
                      className='w-100 receita-custom-height-card'
                      alt='Card Receita' />
                    <span className='position-absolute font-size-17 text-white bg-dia-blue rounded mx-4 px-2 py-0 mb-3'>
                      {receita.node.categoria}
                    </span>
                  </div>
                  <div className='bottom-border-card w-100 py-2 px-4'>
                    <p className='font-size-15 limit-text'><span>{receita.node.title}</span></p>
                  </div>
                </div>
              </Link>
            )
          })
        }
      </Swiper>
    </div>
  )
}

const Receitas = (props) => {
  const data = props.data
  const URL = 'https://www.dia.com.br'

  const breadC = {
    visible: true,
    color: 'dark',
    items: [
      { path: '/', name: 'Home' },
      { path: '/receitas/', name: 'Receitas' }
    ]
  }

  const arrayRecipes = [{
    '@type': 'ListItem',
    position: 1,
    url: `${URL}/receitas/${data.PostMaior.categoriaSlug}/${data.PostMaior.slug}/`
  }]

  data.OtherPosts.edges.map((receita, index) => {
    const count = 2
    arrayRecipes.push({
      '@type': 'ListItem',
      position: count + index,
      url: `${URL}/receitas/${receita.node.categoriaSlug}/${receita.node.slug}/`
    })
  })

  const snippets = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: arrayRecipes
  }

  return (
    <Layout breadCrumb={breadC}>
      <Helmet>
        <html lang="pt-BR" amp/>
        <meta charSet="UTF-8" />
        <title>Receitas DIA - Receitas saborosas, rápidas e fáceis para você!</title>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <meta name="description" content="Confira as nossas receitas fáceis e rápidas, criadas com ingredientes que você encontra no Supermercado DIA mais próximo de você. Receitas de doces, salgados, bolos, massas, pães, bebidas e muito mais."/>
        <meta name="keywords" content="receitas, receita facil, receita de doce, receita de salgado, receita de bolo, receita de pao" />
        <meta name="author" content="DIA Supermercados" />
        <script type="application/ld+json">{`${JSON.stringify(snippets)}`}</script>
      </Helmet>
      <section id="receitas">
        <section id="filtragem-home">
          <div className="menu-receitas">
            <Menu />
          </div>
        </section>
      </section>
      <section className='container mb-4'>
        <div className='row'>
          <div className='col-12 col-lg-8'>
            <div className='d-block d-sm-none' >
              <InputSearch />
            </div>
            <h3 className='text-uppercase text-red mb-3'>Confira nossas últimas receitas</h3>
            <MaiorPost data={data.PostMaior} />
            <OtherPosts data={data.OtherPosts} />
            <OtherPostsMobile data={data.OtherPosts} />
          </div>
          <div className='col-12 col-lg-4'>
            <SideBar />
          </div>
        </div>
      </section>
      <section className='w-100 bg-dia-light-gray'>
        <section className="container py-5 p-0">
          <SectionLancamentos />
        </section>
      </section>
      <div className='mb-5'>
        <BannerDiaExpress banner={data.bannerDiaExpress} bannerMobile={data.bannerDiaExpressMobile} />
      </div>
    </Layout>
  )
}
export default Receitas

export const query = graphql`{
  PostMaior: contentfulReceitas(slug: {eq: "cocada-de-amendoim"}) {
    slug
    title
    categoriaSlug
    categoria
    descricao {
      descricao
    }
    image {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  }
  OtherPosts: allContentfulReceitas(
    filter: {slug: {ne: "cocada-de-amendoim"}}
    skip: 1
    limit: 4
    sort: {order: DESC, fields: updatedAt}
  ) {
    edges {
      node {
        slug
        title
        categoriaSlug
        categoria
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
  bannerDiaExpress: file(
    relativePath: {eq: "receitas/banner-diaexpress-desktop.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  bannerDiaExpressMobile: file(
    relativePath: {eq: "receitas/banner-diaexpress-mobile.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 428, height: 267, layout: CONSTRAINED)
    }
  }
}
`
